
import { Component, Vue } from 'vue-property-decorator';
import { NeLayoutArticlePage } from '@ne/ne-vue2-lib';
import { DynamicWidget } from '@/shared/components';
import { mulGtContents } from '@/api/config';
import { Article } from '@ne/ne-mul-interface-api/dist/pages/article';
import { PageSections, Widget, Widgets } from '@ne/ne-mul-interface-api';

@Component({
  name: 'MulArticle',
  components: { DynamicWidget, NeLayoutArticlePage }
})
export default class MulWidgets extends Vue {
  article: Article | null = null;
  widgets = [] as Widget<Widgets>[];
  async mounted () {
    this.article = (await this.$apiMul.get<Article>(`${mulGtContents}/${this.$route.params.articleId}`)).data;
    this.widgets = (this.article.sections as PageSections).content;
  }

  get attributes () {
    return this.article?.attributes;
  }

  get latestArticles () {
    return this.article?.latestArticles;
  }
}
